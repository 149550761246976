import React, { useEffect } from "react";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  darkBlue,
  lightBlue,
  mainWhite,
  PageTitle,
  InnerWrapper,
  StyledEmail,
  StyledLink,
  screen,
} from "../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { reduceWords, toSlug } from "../helpers";
import { format } from "date-fns";
import { graphql, Link, navigate } from "gatsby";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import ReactPlayer from "react-player";

const Wrapper = styled.div`
  padding-top: 30px;
  background: ${mainWhite};
  @media ${screen.small} {
    padding-top: 60px;
  }
  @media ${screen.medium} {
    padding-top: 130px;
  }
  @media ${screen.large} {
    padding-top: 160px;
  }

  .title {
    font-size: 1.8rem;
    max-width: 745px;
    @media ${screen.small} {
    }
    @media ${screen.medium} {
      font-size: 2.4rem;
    }
    @media ${screen.large} {
      font-size: 3rem;
    }
  }

  .categories {
    color: ${darkBlue};
    font-size: 1rem;
    margin-top: 5px;
    font-weight: 300;
    margin-bottom: 14px;
    @media ${screen.small} {
      font-size: 1.1rem;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  .blog-container {
    padding-bottom: 45px;
    margin-top: 45px;
    @media ${screen.small} {
      display: flex;
    }
    @media ${screen.medium} {
      padding-bottom: 90px;
    }
    .image-banner {
      margin-bottom: 20px;
      @media ${screen.small} {
        display: none;
      }
    }
    .video-banner {
      video {
        max-width: 880px;
        width: 100%;
        outline: none;
      }
      @media ${screen.small} {
        display: none;
      }
      &--embedded {
        position: relative;
        padding-top: 56.25%;
        .react-player {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    &__img {
      width: 100%;
      max-width: 880px;
      margin-bottom: 45px;
      display: none;
      @media ${screen.small} {
        display: block;
      }
    }
    &__video {
      margin-bottom: 45px;
      display: none;
      @media ${screen.small} {
        display: block;
      }
      video {
        max-width: 880px;
        width: 100%;
        outline: none;
      }
      &--embedded {
        .react-player {
          @media ${screen.small} {
            width: 750px !important;
            height: 418px !important;
          }
          @media ${screen.medium} {
            width: 880px !important;
            height: 490px !important;
          }
        }
      }
    }
    &__content {
      max-width: 850px;
      @media ${screen.medium} {
        padding: 0 30px 0 0;
      }
      @media ${screen.large} {
        max-width: 1000px;
        padding: 0 60px 0 0;
      }
      p {
        color: ${darkBlue};
        font-size: 1rem;
        margin-bottom: 15px;
        @media ${screen.large} {
          font-size: 1.1rem;
          margin-bottom: 30px;
        }
      }
      h1 {
        font-size: 1.8rem;
        color: ${darkBlue};
        margin-bottom: 15px;
      }

      .block-img {
        img {
          display: block;
          width: 100%;
        }
      }

      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.3rem;
        color: ${darkBlue};
        margin-bottom: 15px;
        padding-top: 10px;
        @media ${screen.large} {
          font-size: 1.5rem;
          padding-top: 20px;
        }
      }
      li {
        color: ${darkBlue};
        font-size: 1.1rem;
        line-height: 1.5;
        list-style-type: disc;
        margin-left: 15px;
        margin-bottom: 8px;
        @media ${screen.large} {
          margin-left: 18px;
        }
        &:last-child {
          margin-bottom: 8px;
          @media ${screen.large} {
            margin-bottom: 15px;
          }
        }
      }
      a {
        color: ${lightBlue};
        text-decoration: underline;
      }
    }
    &__next-prev {
      margin-top: 45px;
      display: none;
      justify-content: space-between;
      max-width: 1000px;
      @media ${screen.xSmall} {
        display: flex;
      }
      svg {
        color: ${lightBlue};
      }
      .next {
        svg {
          margin-left: 10px;
          margin-bottom: 3px;
          vertical-align: middle;
        }
      }
      .prev {
        svg {
          margin-right: 10px;
          margin-bottom: 3px;
          vertical-align: middle;
        }
      }
      a {
        font-size: 1.15rem;
        color: ${lightBlue};
        &:hover {
          text-decoration: underline;
        }
      }
      &--mobile {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        @media ${screen.xSmall} {
          display: none;
        }
        a {
          width: 50%;
          text-align: center;
          &:nth-child(2) {
            background: none;
            color: ${lightBlue};
            border: 2px solid ${lightBlue};
            margin-left: 12px;
            padding-top: 13px;
            padding-bottom: 13px;
            &:hover {
              background: ${lightBlue};
              color: ${mainWhite};
            }
          }
        }
      }
    }

    .col {
      &--left {
        @media ${screen.small} {
          width: ${(props) => (props.isRelatedArticles ? "71%" : "auto")};
        }
      }

      &--right {
        color: ${darkBlue};
        margin: 90px 0 0 0;
        @media ${screen.small} {
          padding: 0 0 0 50px;
          width: 29%;
          margin: 0;
        }
        @media ${screen.medium} {
          padding: 0 0 0 90px;
        }

        .heading {
          font-size: 1.65rem;
          line-height: 1.2;
          position: relative;
          @media ${screen.medium} {
            font-size: 1.75rem;
          }

          &::after {
            content: "";
            position: absolute;
            width: 53px;
            height: 3px;
            border-radius: 6px;
            background: ${lightBlue};
            left: 0;
            bottom: -10px;
            @media ${screen.small} {
              width: 55px;
              height: 4px;
              bottom: -12px;
            }
          }
        }

        .blog-list {
          margin: 50px 0 0 0;
          @media ${screen.small} {
            margin: 60px 0 0 0;
          }

          &__each {
            border-bottom: 2px #eaeaea solid;
            padding: 0 0 45px 0;

            a {
              color: ${darkBlue};
              transition: color 0.2s ease-in-out;
            }

            &:hover a {
              color: ${lightBlue};
            }

            .img {
            }

            .title {
              display: block;
              font-size: 1.05rem;
              margin: 15px 0 0 0;
              line-height: 1.4;
              @media ${screen.large} {
                font-size: 1.15rem;
              }
            }

            .date {
              display: block;
              font-size: 0.95rem;
              margin: 9px 0 14px 0;
              font-weight: 300;
              color: ${darkBlue};
              @media ${screen.large} {
                font-size: 1rem;
              }
            }

            .description {
              color: ${darkBlue};
              font-size: 0.95rem;
              @media ${screen.large} {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .get-in-touch-container {
    background: ${lightBlue};
    padding: 45px 0;
    @media ${screen.medium} {
      padding: 90px 0;
    }
    &__title {
      color: ${mainWhite};
      font-size: 1.3rem;
      @media ${screen.medium} {
        font-size: 2rem;
      }
    }
    &__description {
      color: ${mainWhite};
      margin-top: 20px;
      font-size: 1.1rem;
      font-weight: 300;
      @media ${screen.medium} {
        font-size: 1.5rem;
        margin-top: 10px;
      }
    }
    a {
      margin-top: 20px;
      width: 100%;
      text-align: center;
      @media ${screen.xSmall} {
        width: auto;
      }
      &:hover {
        background: ${mainWhite};
        color: ${lightBlue};
      }
    }
  }
`;

const Blog = (props) => {
  const title = props.data.blog.data.title.text;
  const titleTag = props.data.blog.data.title_tag
    ? props.data.blog.data.title_tag
    : props.data.blog.data.title.text;
  const metaDescription = props.data.blog.data.meta_description
    ? props.data.blog.data.meta_description
    : reduceWords(props.data.blog.data.content.text, 20);
  const featuredImg = props.data.blog.data.featured_image.gatsbyImageData;
  const thumbnailImg =
    props.data.blog.data.featured_image.thumbnails.thumbnail.url;
  const featuredVideo = props.data.blog.data.featured_video.url;
  const embeddedFeaturedVideoUrl = props.data.blog.data.embed_featured_video;
  const content = props.data.blog.data.content.html;
  const categories = props.data.blog.data.categories.map(
    (item) => item.category.document && item.category.document.data.title.text
  );

  const date = props.data.blog.first_publication_date;
  const editedDate = props.data.blog.data.edit_published_date;
  const nextSlug = props.pageContext.next;
  const prevSlug = props.pageContext.prev;
  const email = props.data.general.data.email_address;

  const { related_article_list } = props.data.blog.data;

  // return array of articles to render or null
  const relatedArticles =
    related_article_list.length && related_article_list[0].blog.document
      ? related_article_list
      : null;

  useEffect(() => {
    const fn = (e) => {
      if (prevSlug && e.keyCode === 37) {
        navigate(`/insights/${prevSlug}`);
      }
      if (nextSlug && e.keyCode === 39) {
        navigate(`/insights/${nextSlug}`);
      }
    };

    document.addEventListener("keyup", fn);

    return () => {
      document.removeEventListener("keyup", fn);
    };
  }, []);

  return (
    <Layout>
      <SEO
        title={titleTag}
        description={metaDescription}
        image={thumbnailImg}
      />
      <Wrapper isRelatedArticles={relatedArticles}>
        <InnerWrapper>
          <PageTitle className="title" dark>
            {title}
          </PageTitle>
          <p className="categories">
            {categories.map((tag, i) => (
              <span key={i}>{tag} / </span>
            ))}
            {editedDate
              ? format(editedDate, "D MMMM YYYY")
              : format(date, "D MMMM YYYY")}
          </p>
          <main className="blog-container">
            <div className="col col--left">
              {embeddedFeaturedVideoUrl ? (
                <div className="video-banner video-banner--embedded">
                  <ReactPlayer
                    className="react-player"
                    url={embeddedFeaturedVideoUrl}
                    width="100%"
                    height="100%"
                  />
                </div>
              ) : featuredVideo ? (
                <div className="video-banner">
                  <video controls preload="auto">
                    <source src={featuredVideo} type="video/mp4" />
                  </video>
                </div>
              ) : featuredImg ? (
                <GatsbyImage
                  className="image-banner"
                  image={featuredImg}
                  alt={`David Haintz - ${title}`}
                />
              ) : (
                <img className="banner" src="x" alt="NOT FOUND" />
              )}

              {embeddedFeaturedVideoUrl ? (
                <div className="blog-container__video blog-container__video--embedded">
                  <ReactPlayer
                    className="react-player"
                    url={embeddedFeaturedVideoUrl}
                  />
                </div>
              ) : featuredVideo ? (
                <div className="blog-container__video">
                  <video controls>
                    <source src={featuredVideo} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <div className="blog-container__img">
                  {featuredImg ? (
                    <GatsbyImage
                      image={featuredImg}
                      alt={`David Haintz - ${title}`}
                    />
                  ) : (
                    <img src="x" alt="NOT FOUND" />
                  )}
                </div>
              )}

              <div
                className="blog-container__content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div className="blog-container__next-prev">
                <p
                  className="prev"
                  style={{ visibility: prevSlug ? "visible" : "hidden" }}
                >
                  <FaChevronLeft />
                  <Link
                    to={`/insights/${prevSlug}`}
                    aria-label="Read previous article."
                  >
                    Previous
                  </Link>
                </p>
                <p
                  className="next"
                  style={{ visibility: nextSlug ? "visible" : "hidden" }}
                >
                  <Link
                    to={`/insights/${nextSlug}`}
                    aria-label="Read next article."
                  >
                    Next
                  </Link>
                  <FaChevronRight />
                </p>
              </div>
              <div className="blog-container__next-prev--mobile">
                <StyledLink
                  to={`/insights/${prevSlug}`}
                  style={{
                    visibility: prevSlug ? "visible" : "hidden",
                    paddingLeft: `10px`,
                  }}
                >
                  <FaChevronLeft style={{ marginRight: "16px" }} />
                  PREV
                </StyledLink>
                <StyledLink
                  to={`/insights/${nextSlug}`}
                  style={{ visibility: nextSlug ? "visible" : "hidden" }}
                >
                  NEXT
                  <FaChevronRight />
                </StyledLink>
              </div>
            </div>
            {relatedArticles && (
              <div className="col col--right">
                <aside>
                  <h2 className="heading">
                    Related Article{relatedArticles.length > 1 && "s"}
                  </h2>

                  <ul className="blog-list">
                    {relatedArticles.map((article) => (
                      <li
                        key={article.blog.document.id}
                        className="blog-list__each"
                      >
                        <Link
                          to={`/insights/${toSlug(
                            article.blog.document.data.title.text
                          )}/`}
                        >
                          <div className="img">
                            <GatsbyImage
                              image={
                                article.blog.document.data.featured_image
                                  .thumbnails.thumbnail.gatsbyImageData
                              }
                              alt={
                                article.blog.document.data.featured_image.alt ||
                                "Thumbnail"
                              }
                            />
                          </div>
                          <span className="title">
                            {article.blog.document.data.title.text}
                          </span>
                          <span className="date">
                            {article.blog.document.data.edit_published_date
                              ? format(
                                  article.blog.document.data
                                    .edit_published_date,
                                  "D MMMM YYYY"
                                )
                              : format(
                                  article.blog.document.first_publication_date,
                                  "D MMMM YYYY"
                                )}
                          </span>
                          <p className="description">
                            {reduceWords(
                              article.blog.document.data.content.text,
                              20
                            )}
                          </p>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </aside>
              </div>
            )}
          </main>
        </InnerWrapper>
        <div className="get-in-touch-container">
          <InnerWrapper>
            <h3 className="get-in-touch-container__title">
              Interested in this topic and want to know more?
            </h3>
            <p className="get-in-touch-container__description">
              Get in touch to have a chat with David
            </p>
            <StyledEmail
              obfuscateChildren={false}
              email={email}
              headers={{
                subject: "I'd like to get in touch with David",
              }}
              normal="true"
            >
              CONTACT <FaChevronRight />
            </StyledEmail>
          </InnerWrapper>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Blog;

export const data = graphql`
  query ($id: String!) {
    blog: prismicBlog(id: { eq: $id }) {
      id
      first_publication_date
      data {
        title {
          text
        }
        categories {
          category {
            document {
              ... on PrismicCategory {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        embed_featured_video
        content {
          html
          text
        }
        featured_image {
          alt
          thumbnails {
            thumbnail {
              url
            }
          }
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        featured_video {
          url
        }
        edit_published_date
        title_tag
        meta_description

        related_article_list {
          blog {
            document {
              ... on PrismicBlog {
                id
                first_publication_date
                data {
                  edit_published_date
                  title {
                    text
                  }
                  content {
                    text
                  }
                  featured_image {
                    alt
                    thumbnails {
                      thumbnail {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          placeholder: BLURRED
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    general: prismicGeneralDetails {
      data {
        email_address
      }
    }
  }
`;
